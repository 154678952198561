/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

//triggered when modal is about to be shown
$('#modal').on('show.bs.modal', function(e) {

    //get data-id attribute of the clicked element
    let targetUrl = $(e.relatedTarget).data('path');
    let modalTitle = $(e.relatedTarget).data('title');
    let modalText = $(e.relatedTarget).data('text');

    $(e.currentTarget).find('form[id="modal-form"]').attr('action', targetUrl);
    $(e.currentTarget).find('h5[id="modal-title"]').html( modalTitle);
    $(e.currentTarget).find('p[id="modal-body"]').html(modalText);
});
